import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';

export interface IWizardAppFocusRuleKeys {
  wrapperForList: {};
}

export type IWizardAppFocusRules = IRuleFn<IWizardAppFocusRuleKeys>;

const WizardAppFocusRuleFn: IWizardAppFocusRules = props => {
  const {} = props.theme;

  return {
    wrapperForList: {
      display: 'flex',
      rowGap: 8,
      flexDirection: 'column',
    },
  };
};

export const WizardAppFocusRules = createCachedStyle(WizardAppFocusRuleFn);
