import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';

export interface IWizardOrderMethodProduct2RuleKeys {
  wrapper: {};
}

export type IWizardOrderMethodProduct2Rules = IRuleFn<IWizardOrderMethodProduct2RuleKeys>;

const WizardOrderMethodProduct2RuleFn: IWizardOrderMethodProduct2Rules = props => {
  const {} = props.theme;

  return {
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: '10px',
    },
  };
};

export const WizardOrderMethodProduct2Rules = createCachedStyle(WizardOrderMethodProduct2RuleFn);
