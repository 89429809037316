import { FC, memo, useEffect, useMemo, useState } from 'react';
import Footer from 'src/components/Footer/Footer';
import { Layout } from 'src/components/Layout/Layout';
import { Button } from 'src/components/UI/Button/Button';
import Text from 'src/components/UI/Text/Text';
import { useStyle } from 'src/utils/theme/useStyle';
import { storeSetupPageRules } from './StoreSetupPage.style';
import Container from 'src/components/Container/Container';
import { ListButton } from 'src/components/UI/ListButton/ListButton';
import useLoader from 'src/hooks/useLoader';
import { useModalState } from 'src/hooks/useModalState';
import { ModalUniversal } from 'src/components/ModalUniversal/ModalUniversal';
import { wizardStateSelector, resetNetworkStatus, setStoreFocus } from 'src/redux/slices/wizardSlice';
import { Option } from 'src/components/UI/Option/Option';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { useNavigate } from 'react-router-dom';
import { ClientSupportForm } from 'src/components/ClientSupportForm/ClientSupportForm';
import { WelcomeMessage } from 'src/components/WelcomeMessage/WelcomeMessage';
import { NetworkStatus } from 'src/utils/network/network.constant';
import { wizardGetState } from 'src/redux/api/wizard/wizardGetState';
import { WizardTranslator } from 'src/redux/translators/wizardTranslator';
import { wizardSaveState } from 'src/redux/api/wizard/wizardSaveState';
import useBackButton from 'src/hooks/useBackButton';
import { useTelegram } from 'src/utils/telegramProvider';
import { MaintenanceEnabledForm } from 'src/components/MaintenanceEnabledForm/MaintenanceEnabledForm';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';
import { useCurrencyData } from 'src/utils/hooks/useCurrencyData';
import { useLanguageData } from 'src/utils/hooks/useLanguageData';
import useColorName from 'src/hooks/useColorName';
import ModalError from 'src/components/ModalError/ModalError';
import { WizardSaveStateRequest } from '@teleport/schemas-protobuf';
import { useLocalStorage } from 'src/hooks/useLocalStorage';
import { ModalWarningAsyncChanges } from 'src/components/ModalWarningAsyncChanges/ModalWarningAsyncChanges';
import { IntegrationTelegramForm } from '../../components/IntegrationTelegramForm/IntegrationTelegramForm';
import { getChannelIntegrationDetail, integrationState, resetIntegrationError } from 'src/redux/slices/integrationSlice';
import { useAppFocusList } from '../../hooks/useAppFocusList';
import { StoreFocusCases } from 'src/types/wizard';
import { ModalUniversalContentType } from './StoreSetupPageEnums';
import { togglesState } from 'src/redux/slices/configSlice';
import { useProductTypeList } from 'src/components/UI/Lists/ListStoreFocus/useProductTypeList';
import ModalList from 'src/components/UI/Lists/ListStoreFocus/components/ModalList/ModalList';



export const StoreSetupPage: FC = memo(function StoreSetupPage() {
  const { css } = useStyle(storeSetupPageRules);
  const { hideLoader } = useLoader();
  const navigate = useNavigate();
  const BackButton = useBackButton();
  const dispatch = useAppDispatch();
  const { wizard: wizardData, networkStatus, saveNetworkStatus } = useAppSelector(wizardStateSelector);
  const { networkStatus: integrationNetworkStatus, integration, errorText} = useAppSelector(integrationState);
  const Telegram = useTelegram();
  const [renderErrorModal, activeErrorModal, openErrorModal, closeErrorModal] = useModalState();
  const [renderErrorModalIntegration, activeErrorModalIntegration, openErrorModalIntegration, closeErrorModalIntegration] = useModalState();
  const [noShowModalWarningAsyncChanges, setNoShowModalWarningAsyncChanges] = useLocalStorage('noShowModalWarningAsyncChanges');
  const { productEnabled2_1 } = useAppSelector(togglesState);
  const [renderModal, activeModal, openModal, closeModal] = useModalState();

  const closeModalStoreFocusClose = () => {
    closeModal();
  };


  const { t } = useTranslation();

  const currencies = useCurrencyData();
  const storeFocuses = useAppFocusList();
  const languages = useLanguageData();
  const products = useProductTypeList();

  useEffect(() => {
    if (integrationNetworkStatus === NetworkStatus.None) {
      dispatch(getChannelIntegrationDetail())
    }
    if (networkStatus === NetworkStatus.None) {
      dispatch(wizardGetState({}));
    }
    if (networkStatus === NetworkStatus.Done) {
      hideLoader();
    }
  }, [networkStatus, dispatch, hideLoader, integrationNetworkStatus]);

  useEffect(() => {
    if (errorText && !errorText.includes('bot_is_not_admin')) {
      openErrorModalIntegration()
    }
  }, [errorText, openErrorModalIntegration])

  const onCloseErrorIntegrationModal = () => {
    dispatch(resetIntegrationError())
    closeErrorModalIntegration()
  }

  useEffect(() => {
    BackButton.onClickCustom(goBack);
    function goBack() {
      navigate('/');
    }
    return () => {
      BackButton?.offClickCustom(goBack);
    };
  }, [BackButton, navigate]);

  const [renderModalUniversal, activeModalUniversal, openModalUniversal, closeModalUniversal] =
    useModalState();
  const [renderAsyncModal, activeAsyncModal, openAsyncModal, closeAsyncModal] = useModalState();

  const [modalUniversalContentType, setModalUniversalContentType] = useState('');

  const [options, setOptions] = useState([]);
  const [optionsTitle, setOptionsTitle] = useState('');

  const sendLanguageOrCurrency = (wizardStateRequest: WizardSaveStateRequest) => {
    dispatch(wizardSaveState({requestData: wizardStateRequest})).unwrap().then( res => {
      if (res.wizardData) {
        closeModalUniversal();
      }else {
        openErrorModal()
      }
    })
  }

  const onChangeLanguage = value => {
    const wizardStateRequest = WizardTranslator.toWizardSaveStateRequest({...wizardData, language: Number(value)}, true);
    sendLanguageOrCurrency(wizardStateRequest)
  };
  const onChangeCurrency = value => {
    const wizardStateRequest = WizardTranslator.toWizardSaveStateRequest({...wizardData, currency: Number(value)}, true);
    sendLanguageOrCurrency(wizardStateRequest)
  };

  const onClickListButton = (e: React.SyntheticEvent) => {
    const target = e.target as HTMLElement;
    const targetParentId = target.closest('.list-button')?.id;
    if (!target.id && !targetParentId) return;
    const targetId = target.id ? target.id : targetParentId;
    switch (targetId) {
      case ModalUniversalContentType.storeСard:
        navigate('/profile');
        return;
      case ModalUniversalContentType.languages:
        setOptionsTitle(t('storeSetupPage.language'));
        setOptions(languages);
        setModalUniversalContentType(ModalUniversalContentType.languages);
        openModalUniversal();
        return;
      case ModalUniversalContentType.currency:
        setOptionsTitle(t('storeSetupPage.currency'));
        setOptions(currencies);
        setModalUniversalContentType(ModalUniversalContentType.currency);
        openModalUniversal();
        return;
      case ModalUniversalContentType.products:
        setOptions(products);
        openModal();
        return
      case ModalUniversalContentType.storeFocus:
        setOptionsTitle(t('storeSetupPage.applicationTopic'));
        setOptions(storeFocuses);
        setModalUniversalContentType(ModalUniversalContentType.storeFocus);
        openModalUniversal();
        return;
      case ModalUniversalContentType.color:
        navigate('/select-color');
        return;
      case ModalUniversalContentType.usersSupport:
        setOptionsTitle(t('storeSetupPage.customerSupport'));
        setModalUniversalContentType(ModalUniversalContentType.usersSupport);
        openModalUniversal();
        return;
      case ModalUniversalContentType.welcomeMessage:
        setOptionsTitle(t('storeSetupPage.welcomeMessage'));
        setModalUniversalContentType(ModalUniversalContentType.welcomeMessage);
        openModalUniversal();
        return;
      case ModalUniversalContentType.integrationIntoChannel:
        setOptionsTitle(t('storeSetupPage.integrationIntoTheChannel'));
        setModalUniversalContentType(ModalUniversalContentType.integrationIntoChannel);
        openModalUniversal();
        return;
      case ModalUniversalContentType.maintenanceEnabled:
        setOptionsTitle(t('storeSetupPage.disablingTheStore'));
        setModalUniversalContentType(ModalUniversalContentType.maintenanceEnabled);
        openModalUniversal();
        return;
      default:
        setOptionsTitle('');
        setOptions([]);
        return;
    }
  };

  const changeStoreFocus = (storeFocus: string) => {
    dispatch(
      setStoreFocus({
        case: productEnabled2_1? storeFocus as StoreFocusCases : 'productType',
        value: productEnabled2_1? 0 : Number(storeFocus),
      }),
    );

    const wizardStateRequest = WizardTranslator.toWizardSaveStateRequest(
      // 'end',
      { ...wizardData, storeFocus: {
        case: productEnabled2_1? storeFocus as StoreFocusCases : 'productType',
        value: productEnabled2_1? 0 : Number(storeFocus),
      } },
      true,
    );
    dispatch(wizardSaveState({ requestData: wizardStateRequest }));
    closeModalUniversal();
    // Remove when remove productEnabled2_1
    closeModal()
  };

  const connectToSupport = () => {
    Telegram.webApp.openTelegramLink('https://t.me/TeleportHelpBot');
  };

  const closeModalUniversalFuntion = () => {
    closeModalUniversal();
  }

  const getColorName = useColorName()

  useEffect(() => {
    if (saveNetworkStatus === NetworkStatus.Done) {
      setTimeout(() => {
        if (!noShowModalWarningAsyncChanges) {
          openAsyncModal();
        }
      }, 1500);
      dispatch(resetNetworkStatus('saveNetworkStatus'));
    }
  }, [saveNetworkStatus, noShowModalWarningAsyncChanges, dispatch, openAsyncModal]);

  const currentCurrency = useMemo(() => {
    const filteredCurrency = currencies.filter(el => `${el.value}` === `${wizardData.currency}`)
    if (filteredCurrency[0] && filteredCurrency[0].value) {
      if (filteredCurrency[0].symbol === 'stars') {
        return 'Stars'
      }
      return filteredCurrency[0].subtitle
    } else {
      return ''
    }
  }, [wizardData.currency, currencies])

  const currentLanguage = useMemo(() => {
    const filteredLanguage = languages.filter(el => `${el.value}` === `${wizardData.language}`)
    if (filteredLanguage[0] && filteredLanguage[0].value) {
      return filteredLanguage[0].title
    } else {
      return ''
    }
  }, [wizardData.language, languages])
  const currentStoreFocuse = useMemo(() => {
    const filteredStoreFocuse = storeFocuses.filter(el => `${el.type}` === `${wizardData.storeFocus.case}`)
    if (filteredStoreFocuse[0] && filteredStoreFocuse[0].type) {
      return filteredStoreFocuse[0].title
    } else {
      return ''
    }
  }, [wizardData.storeFocus.case, storeFocuses])

  return (
    <Layout
      header={
        <header className={`${css.header} header`}>
          <Text
            text={t('storeSetupPage.storeSettings')}
            mod="title"
            fontWeight={800}
            fontSize={20}
            lineHeight={22}
          />
        </header>
      }
      footer={
        <Footer>
          <Button text={t('storeSetupPage.contactSupport')} onClick={connectToSupport} propsStyles={{ width: '100%' }} />
        </Footer>
      }
    >
      <Container>
        <div className={css.content}>

          <div className={css.grid}>
            <ListButton
              id={ModalUniversalContentType.storeСard}
              title={t('storeSetupPage.storeCard')}
              placeholder={t('storeSetupPage.storeNamePhotoAndDescription')}
              onClick={onClickListButton}
              padding="16px"
            />
            <ListButton
              id={ModalUniversalContentType.welcomeMessage}
              title={t('storeSetupPage.welcomeMessage')}
              placeholder={wizardData.welcomeMessage ?? t('storeSetupPage.welcomeMessage')}
              onClick={onClickListButton}
              padding="16px"
            />
            <ListButton
              id={ModalUniversalContentType.usersSupport}
              title={t('storeSetupPage.customerSupport')}
              placeholder={`${wizardData?.supportLink}`}
              onClick={onClickListButton}
              padding="16px"
            />
            {productEnabled2_1 && (
              <ListButton
                id={ModalUniversalContentType.storeFocus}
                title={t('storeSetupPage.applicationTopic')}
                placeholder={currentStoreFocuse}
                onClick={onClickListButton}
                padding="16px"
              />
            )}
            <ListButton
              id={ModalUniversalContentType.languages}
              title={t('storeSetupPage.language')}
              placeholder={currentLanguage}
              onClick={onClickListButton}
              padding="16px"
            />
            <ListButton
              id={ModalUniversalContentType.currency}
              title={t('storeSetupPage.currency')}
              placeholder={currentCurrency}
              onClick={onClickListButton}
              padding="16px"
            />
            {
              !productEnabled2_1 && (
                <ListButton
                  id={ModalUniversalContentType.products}
                  title={t('storeSetupPage.products')}
                  placeholder={wizardData?.storeFocus?.value? `${products[wizardData?.storeFocus?.value - 1].text}` : ''}
                  onClick={onClickListButton}
                  padding="16px"
                />
              )
            }
            <ListButton
              id={ModalUniversalContentType.color}
              title={t('storeSetupPage.color')}
              placeholder={wizardData.accentColor ? getColorName(wizardData.accentColor) : t('storeSetupPage.color')}
              onClick={onClickListButton}
              padding="16px"
            />
            <ListButton
              id={ModalUniversalContentType.integrationIntoChannel}
              title={t('storeSetupPage.integrationIntoTheChannel')}
              placeholder={integration.channelLink ? `@${integration.channelLink.split('/').pop()}` : t('storeSetupPage.addApplicationInformation')}
              onClick={onClickListButton}
              padding="16px"
            />
            <ListButton
              id={ModalUniversalContentType.maintenanceEnabled}
              title={t('storeSetupPage.disablingTheStore')}
              placeholder={wizardData.maintenanceEnabled ? t('storeSetupPage.storeDisabled') : t('storeSetupPage.storeEnabled')}
              onClick={onClickListButton}
              padding="16px"
            />
          </div>
        </div>
      </Container>
      {renderModalUniversal && (
        <ModalUniversal
          title={optionsTitle}
          active={activeModalUniversal}
          onClose={closeModalUniversalFuntion}
          paddingBottom={modalUniversalContentType === ModalUniversalContentType.integrationIntoChannel ? 126 : undefined}
        >
          <>
            <div className={css.grid}>
              {modalUniversalContentType === ModalUniversalContentType.currency &&
                options.map(el => (
                  <Option
                    key={el.value}
                    value={el.value}
                    title={el.title}
                    subtitle={el.subtitle}
                    checked={`${wizardData?.currency}` === `${el.value}`}
                    type="radio"
                    symbol={el.symbol}
                    onChange={onChangeCurrency}
                    soon={el.soon}
                    disabled={el.disabled || saveNetworkStatus === NetworkStatus.Loading}
                  />
                ))}
              {modalUniversalContentType === ModalUniversalContentType.languages &&
                options.map(el => {
                  return (
                    <Option
                      key={el.value}
                      value={el.value}
                      title={el.title}
                      subtitle={el.subtitle}
                      checked={`${wizardData?.language}` === `${el.value}`}
                      type="radio"
                      symbol={el.symbol}
                      onChange={onChangeLanguage}
                      soon={el.soon}
                      disabled={el.disabled || saveNetworkStatus === NetworkStatus.Loading}
                    />
                  )
                })}
              {modalUniversalContentType === ModalUniversalContentType.storeFocus &&
                options.map(el => {
                  return (
                    <Option
                      key={el.id}
                      value={el.type}
                      title={el.title}
                      subtitle={el.text}
                      checked={`${wizardData?.storeFocus.case}` === `${el.type}`}
                      type="radio"
                      onChange={changeStoreFocus}
                      disabled={el.disabled || saveNetworkStatus === NetworkStatus.Loading}
                    />
                  )
                })}
            </div>

            {modalUniversalContentType === ModalUniversalContentType.usersSupport && (
              <ClientSupportForm sendRequest={true} closeModal={closeModalUniversal} />
            )}
            {modalUniversalContentType === ModalUniversalContentType.welcomeMessage && <WelcomeMessage />}
            {modalUniversalContentType === ModalUniversalContentType.integrationIntoChannel && (
              <IntegrationTelegramForm onSubmitCallback={closeModalUniversal} />
            )}
            {modalUniversalContentType === ModalUniversalContentType.maintenanceEnabled && (
              <MaintenanceEnabledForm onSubmitCallback={closeModalUniversal} />
            )}
          </>
        </ModalUniversal>
      )}

      {renderModal && !productEnabled2_1 && (
        <ModalList
          options={options}
          selectedValue={wizardData?.storeFocus?.value}
          active={activeModal}
          onClose={closeModalStoreFocusClose}
          onChange={changeStoreFocus}
          preventCloseOnChange={true}
        />
      )}

      {renderErrorModal && (
        <ModalError
          deep={2}
          onClose={closeErrorModal}
          title={t('storeSetupPage.error')}
          active={activeErrorModal}
        />
      )}
      {renderErrorModalIntegration && (
        <ModalError
          deep={2}
          onClose={onCloseErrorIntegrationModal}
          title={errorText}
          active={activeErrorModalIntegration}
        />
      )}
      {renderAsyncModal && <ModalWarningAsyncChanges onClickNoShowMore={setNoShowModalWarningAsyncChanges} onClose={closeAsyncModal} active={activeAsyncModal} />}
    </Layout>
  );
});
