import { FC, SVGAttributes, memo } from 'react';

// icons
import { ReactComponent as Heart } from 'src/assets/icons/heart.svg';
import { ReactComponent as Basket } from 'src/assets/icons/shop-basket.svg';
import { ReactComponent as Cross } from 'src/assets/icons/cross.svg';
import { ReactComponent as Sort } from 'src/assets/icons/sort.svg';
import { ReactComponent as Plus } from 'src/assets/icons/plus.svg';
import { ReactComponent as Clothes } from 'src/assets/icons/product-types/IconView.svg';
import { ReactComponent as Electronics } from 'src/assets/icons/product-types/IconView-0.svg';
import { ReactComponent as Shoes } from 'src/assets/icons/product-types/IconView-1.svg';
import { ReactComponent as Home } from 'src/assets/icons/product-types/IconView-2.svg';
import { ReactComponent as Children } from 'src/assets/icons/product-types/IconView-3.svg';
import { ReactComponent as Beauty } from 'src/assets/icons/product-types/IconView-4.svg';
import { ReactComponent as Household } from 'src/assets/icons/product-types/IconView-5.svg';
import { ReactComponent as Sport } from 'src/assets/icons/product-types/IconView-6.svg';
import { ReactComponent as Construction } from 'src/assets/icons/product-types/IconView-7.svg';
import { ReactComponent as Food } from 'src/assets/icons/product-types/IconView-8.svg';
import { ReactComponent as Pharmacy } from 'src/assets/icons/product-types/IconView-9.svg';
import { ReactComponent as Animals } from 'src/assets/icons/product-types/IconView-10.svg';
import { ReactComponent as Book } from 'src/assets/icons/product-types/IconView-11.svg';
import { ReactComponent as Tourism } from 'src/assets/icons/product-types/IconView-12.svg';
import { ReactComponent as Automotive } from 'src/assets/icons/product-types/IconView-13.svg';
import { ReactComponent as Furniture } from 'src/assets/icons/product-types/IconView-14.svg';
import { ReactComponent as Hobby } from 'src/assets/icons/product-types/IconView-15.svg';
import { ReactComponent as Jewerly } from 'src/assets/icons/product-types/IconView-16.svg';
import { ReactComponent as Accessories } from 'src/assets/icons/product-types/IconView-17.svg';
import { ReactComponent as Gaming } from 'src/assets/icons/product-types/IconView-18.svg';
import { ReactComponent as Stationery } from 'src/assets/icons/product-types/IconView-19.svg';
import { ReactComponent as Adult } from 'src/assets/icons/product-types/IconView-20.svg';
import { ReactComponent as Antique } from 'src/assets/icons/product-types/IconView-21.svg';
import { ReactComponent as Digital } from 'src/assets/icons/product-types/IconView-22.svg';
import { ReactComponent as Hygiene } from 'src/assets/icons/product-types/IconView-23.svg';
import { ReactComponent as Music } from 'src/assets/icons/product-types/IconView-24.svg';
import { ReactComponent as Vehicle } from 'src/assets/icons/product-types/IconView-25.svg';
import { ReactComponent as Lock } from 'src/assets/icons/lock.svg';
import { ReactComponent as ColorPicker } from 'src/assets/icons/color-picker.svg';
import { ReactComponent as People } from 'src/assets/icons/people.svg';
import { ReactComponent as Edit } from 'src/assets/icons/Icon-edit.svg';
import { ReactComponent as NoImage } from 'src/assets/svg/no-image.svg';
import { ReactComponent as View } from 'src/assets/icons/view.svg';
import { ReactComponent as NoView } from 'src/assets/icons/no-view.svg';
import { ReactComponent as Delete } from 'src/assets/icons/delete.svg';
import { ReactComponent as Share } from 'src/assets/icons/share.svg';
import { ReactComponent as Qr } from 'src/assets/icons/qr.svg';

// icons-clear
import { ReactComponent as Check } from 'src/assets/icons-clear/check.svg';
import { ReactComponent as CrossClear } from 'src/assets/icons-clear/cross.svg';
import { ReactComponent as Bookmark } from 'src/assets/icons-clear/bookmark.svg';
import { ReactComponent as Filter } from 'src/assets/icons-clear/filter.svg';
import { ReactComponent as Guard } from 'src/assets/icons-clear/guard.svg';
import { ReactComponent as Bag } from 'src/assets/icons-clear/bag.svg';
import { ReactComponent as Business } from 'src/assets/icons-clear/business.svg';
import { ReactComponent as ChevronRight } from 'src/assets/icons-clear/chevron-right.svg';
import { ReactComponent as Info } from 'src/assets/icons-clear/info.svg';

import { ReactComponent as BlockOrder } from 'src/assets/icons-clear/settings/block-order.svg';
import { ReactComponent as Categories } from 'src/assets/icons-clear/settings/categories.svg';
import { ReactComponent as Selections } from 'src/assets/icons-clear/settings/selections.svg';
import { ReactComponent as Delivery } from 'src/assets/icons-clear/settings/delivery.svg';
import { ReactComponent as Checkout } from 'src/assets/icons-clear/settings/checkout.svg';
import { ReactComponent as General } from 'src/assets/icons-clear/settings/general.svg';
import { ReactComponent as Modificators } from 'src/assets/icons-clear/settings/modificators.svg';
import { ReactComponent as Newsletter } from 'src/assets/icons-clear/settings/newsletter.svg';
import { ReactComponent as Payment } from 'src/assets/icons-clear/settings/payment.svg';
import { ReactComponent as Promocodes } from 'src/assets/icons-clear/settings/promocodes.svg';
import { ReactComponent as Recommendations } from '../../../assets/icons-clear/settings/recommendations.svg';
import { ReactComponent as Slider } from 'src/assets/icons-clear/settings/slider.svg';
import { ReactComponent as Stories } from 'src/assets/icons-clear/settings/stories.svg';
import { ReactComponent as TextBlock } from 'src/assets/icons-clear/settings/text-block.svg';
import { ReactComponent as StoriesLarge } from 'src/assets/icons-clear/settings-large/stories-large.svg';
import { ReactComponent as TextBlocksLarge } from 'src/assets/icons-clear/settings-large/text-blocks-large.svg';
import { ReactComponent as CategoriesLarge } from 'src/assets/icons-clear/settings-large/categories-large.svg';
import { ReactComponent as DataLarge } from 'src/assets/icons-clear/settings-large/data-large.svg';
import { ReactComponent as GoodsLarge } from 'src/assets/icons-clear/settings-large/products-large.svg';
import { ReactComponent as DevicesLarge } from 'src/assets/icons-clear/settings-large/devices-large.svg';
import { ReactComponent as SliderLarge } from 'src/assets/icons-clear/settings-large/slider-large.svg';
import { ReactComponent as SelectionsLarge } from 'src/assets/icons-clear/settings-large/selections-large.svg';
import { ReactComponent as PaymentLarge } from 'src/assets/icons-clear/settings-large/payment-large.svg';
import { ReactComponent as ModifierLarge } from 'src/assets/icons-clear/settings-large/modifier-large.svg';
import { ReactComponent as NewslettersLarge } from 'src/assets/icons-clear/settings-large/newsletters-large.svg';
import { ReactComponent as Bars } from 'src/assets/icons-clear/bars.svg';
import { ReactComponent as Link } from 'src/assets/icons-clear/link.svg';
import { ReactComponent as ArrowLeft } from 'src/assets/icons-clear/arrow-left.svg';
import { ReactComponent as Copy } from 'src/assets/icons-clear/Copy.svg';
import { ReactComponent as Statistics } from 'src/assets/icons-clear/statistics.svg';

export type IconList =
  | 'heart'
  | 'basket'
  | 'cross'
  | 'cross-clear'
  | 'sort'
  | 'filter'
  | 'info'
  | 'chevron-right'
  | 'check'
  | 'bookmark'
  | 'plus'
  | 'clothes'
  | 'electronics'
  | 'shoes'
  | 'home'
  | 'children'
  | 'beauty'
  | 'household'
  | 'sport'
  | 'construction'
  | 'food'
  | 'pharmacy'
  | 'animals'
  | 'book'
  | 'tourism'
  | 'automotive'
  | 'furniture'
  | 'hobby'
  | 'jewerly'
  | 'accessories'
  | 'gaming'
  | 'stationery'
  | 'adult'
  | 'antique'
  | 'digital'
  | 'hygiene'
  | 'music'
  | 'vehicle'
  | 'lock'
  | 'guard'
  | 'color-picker'
  | 'bag'
  | 'business'
  | 'people'
  | 'block-order'
  | 'categories'
  | 'selections'
  | 'delivery'
  | 'checkout'
  | 'general'
  | 'modificators'
  | 'newsletter'
  | 'payment'
  | 'promocodes'
  | 'recommendations'
  | 'slider'
  | 'stories'
  | 'text-block'
  | 'stories-large'
  | 'text-blocks-large'
  | 'categories-large'
  | 'data-large'
  | 'products-large'
  | 'devices-large'
  | 'slider-large'
  | 'selections-large'
  | 'payment-large'
  | 'modifier-large'
  | 'newsletters-large'
  | 'bars'
  | 'link'
  | 'arrow-left'
  | 'edit'
  | 'view'
  | 'no-view'
  | 'no-image'
  | 'delete'
  | 'copy'
  | 'share'
  | 'qr'
  | 'statistics';

interface IProps {
  name: IconList;
  properties?: SVGAttributes<unknown>;
}
// TODO https://app.clickup.com/t/86c0f8vm2
export const Icon: FC<IProps> = memo(function Icon(props) {
  const { name, properties = {} } = props;
  switch (name) {
    case 'heart':
      return <Heart {...properties} />;
    case 'basket':
      return <Basket {...properties} />;
    case 'cross':
      return <Cross {...properties} />;
    case 'cross-clear':
      return <CrossClear {...properties} />;
    case 'sort':
      return <Sort {...properties} />;
    case 'filter':
      return <Filter {...properties} />;
    case 'info':
      return <Info {...properties} />;
    case 'chevron-right':
      return <ChevronRight {...properties} />;
    case 'check':
      return <Check {...properties} />;
    case 'bookmark':
      return <Bookmark {...properties} />;
    case 'plus':
      return <Plus {...properties} />;
    case 'clothes':
      return <Clothes {...properties} />;
    case 'electronics':
      return <Electronics {...properties} />;
    case 'shoes':
      return <Shoes {...properties} />;
    case 'home':
      return <Home {...properties} />;
    case 'children':
      return <Children {...properties} />;
    case 'beauty':
      return <Beauty {...properties} />;
    case 'household':
      return <Household {...properties} />;
    case 'sport':
      return <Sport {...properties} />;
    case 'construction':
      return <Construction {...properties} />;
    case 'food':
      return <Food {...properties} />;
    case 'pharmacy':
      return <Pharmacy {...properties} />;
    case 'animals':
      return <Animals {...properties} />;
    case 'book':
      return <Book {...properties} />;
    case 'tourism':
      return <Tourism {...properties} />;
    case 'automotive':
      return <Automotive {...properties} />;
    case 'furniture':
      return <Furniture {...properties} />;
    case 'hobby':
      return <Hobby {...properties} />;
    case 'jewerly':
      return <Jewerly {...properties} />;
    case 'accessories':
      return <Accessories {...properties} />;
    case 'gaming':
      return <Gaming {...properties} />;
    case 'stationery':
      return <Stationery {...properties} />;
    case 'adult':
      return <Adult {...properties} />;
    case 'antique':
      return <Antique {...properties} />;
    case 'digital':
      return <Digital {...properties} />;
    case 'hygiene':
      return <Hygiene {...properties} />;
    case 'music':
      return <Music {...properties} />;
    case 'vehicle':
      return <Vehicle {...properties} />;
    case 'guard':
      return <Guard {...properties} />;
    case 'lock':
      return <Lock {...properties} />;
    case 'color-picker':
      return <ColorPicker {...properties} />;
    case 'bag':
      return <Bag {...properties} />;
    case 'business':
      return <Business {...properties} />;
    case 'people':
      return <People {...properties} />;
    case 'block-order':
      return <BlockOrder {...properties} />;
    case 'categories':
      return <Categories {...properties} />;
    case 'selections':
      return <Selections {...properties} />;
    case 'delivery':
      return <Delivery {...properties} />;
    case 'checkout':
      return <Checkout {...properties} />;
    case 'general':
      return <General {...properties} />;
    case 'modificators':
      return <Modificators {...properties} />;
    case 'newsletter':
      return <Newsletter {...properties} />;
    case 'payment':
      return <Payment {...properties} />;
    case 'promocodes':
      return <Promocodes {...properties} />;
    case 'recommendations':
      return <Recommendations {...properties} />;
    case 'slider':
      return <Slider {...properties} />;
    case 'stories':
      return <Stories {...properties} />;
    case 'text-block':
      return <TextBlock {...properties} />;
    case 'stories-large':
      return <StoriesLarge {...properties} />;
    case 'text-blocks-large':
      return <TextBlocksLarge {...properties} />;
    case 'categories-large':
      return <CategoriesLarge {...properties} />;
    case 'data-large':
      return <DataLarge {...properties} />;
    case 'products-large':
      return <GoodsLarge {...properties} />;
    case 'devices-large':
      return <DevicesLarge {...properties} />;
    case 'slider-large':
      return <SliderLarge {...properties} />;
    case 'selections-large':
      return <SelectionsLarge {...properties} />;
    case 'payment-large':
      return <PaymentLarge {...properties} />;
    case 'modifier-large':
      return <ModifierLarge {...properties} />;
    case 'newsletters-large':
      return <NewslettersLarge {...properties} />;
    case 'bars':
      return <Bars {...properties} />;
    case 'link':
      return <Link {...properties} />;
    case 'arrow-left':
      return <ArrowLeft {...properties} />;
    case 'edit':
      return <Edit {...properties} />;
    case 'no-image':
      return <NoImage {...properties} />;
    case 'view':
      return <View {...properties} />;
    case 'no-view':
      return <NoView {...properties} />;
    case 'delete':
      return <Delete {...properties} />;
    case 'copy':
      return <Copy {...properties} />;
    case 'share':
      return <Share {...properties} />;
    case 'qr':
      return <Qr {...properties} />;
    case 'statistics':
      return <Statistics {...properties} />;
  }
});
