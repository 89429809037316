import { CourseType, EventType, ProductType, ServiceType } from '@teleport/schemas-protobuf';
import type {
  DigitalType,
  EcomStoreType,
  FoodType,
  HotelType
} from '@teleport/schemas-protobuf/hub/v1/hub_wizard_dictionary_pb';

enum WIZARD_STEPS {
  NAME_AND_LOGO = 'shopName',
  COLOR = 'color',
  PRODUCT_TYPE = 'productType',
  ORDER_METHOD = 'orderMethod',
  HAS_BASKET = 'hasBasket',
}

type StoreFocus =
  | {
      value: ProductType;
      case: 'productType';
    }
  | {
      value: ServiceType;
      case: 'serviceType';
    }
  | {
      value: CourseType;
      case: 'courseType';
    }
  | {
      value: EventType;
      case: 'eventType';
    }
  | { case: undefined; value?: undefined };

type AppFocus =
  {
  value: EcomStoreType;
  case: 'ecomStoreType';
} | {
  value: ServiceType;
  case: 'serviceType';
} | {
  value: CourseType;
  case: 'courseType';
} | {
  value: EventType;
  case: 'eventType';
} | {
  value: FoodType;
  case: 'foodType';
} | {
  value: DigitalType;
  case: 'digitalType';
} | {
  value: HotelType;
  case: 'hotelType';
} | { case: undefined; value?: undefined }

enum StoreFocusCases {
  productType = 'productType',
  serviceType = 'serviceType',
  courseType= 'courseType',
  eventType = 'eventType',
}

enum AppFocusCases {
  ecomStoreType = 'ecomStoreType',
  serviceType = 'serviceType',
  courseType= 'courseType',
  eventType = 'eventType',
  foodType = 'foodType',
  digitalType = 'digitalType',
  hotelType = 'hotelType',
}

type WizardSteps = WIZARD_STEPS | 'start' | 'end';

export { WizardSteps, WIZARD_STEPS, StoreFocus, StoreFocusCases, AppFocus, AppFocusCases };
