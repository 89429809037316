import { useTranslation } from '../utils/i18n/hooks/useTranslation';
import { useMemo } from 'react';
import { AppFocusCases } from 'src/types/wizard';

interface IStoreFocusType {
  id: number,
  title: string,
  text: string,
  type: AppFocusCases
}

export const useAppFocusList = (): IStoreFocusType[] => {
  const {t} = useTranslation();

  return useMemo(() => [
    {
      id: 1,
      title: t('wizardStepProductType.foodAndGroceries'),
      text: t('wizardStepProductType.suitableForDeliveringReadyToEatMealsOrGroceriesToHomesOrOffices'),
      type: AppFocusCases.foodType,
    },
    {
      id: 2,
      title: t('wizardStepProductType.onlineStores'),
      text: t('wizardStepProductType.forBusinessesDeliveringGoodsViaCourierOrToPickupPoints'),
      type: AppFocusCases.ecomStoreType,
    },
    {
      id: 3,
      title: t('wizardStepProductType.digitalProducts'),
      text: t('wizardStepProductType.forSellingAndDeliveringDigitalItemsLikeAppsGamesOrSubscriptions'),
      type: AppFocusCases.digitalType,
    },
    {
      id: 4,
      title: t('wizardStepProductType.coursesAndEducation'),
      text: t('wizardStepProductType.idealForSellingOnlineCoursesAndTrainingProgramsAccessibleViaTheInternet'),
      type: AppFocusCases.courseType,
    },
    {
      id: 5,
      title: t('wizardStepProductType.services'),
      text: t('wizardStepProductType.forOfferingServicesThatCanBePerformedRemotelyOrOnSite'),
      type: AppFocusCases.serviceType,
    },
    {
      id: 6,
      title: t('wizardStepProductType.eventsAndActivities'),
      text: t('wizardStepProductType.forSellingTicketsAndOrganizingEventsBothOnlineAndOffline'),
      type: AppFocusCases.eventType,
    },
    {
      id: 7,
      title: t('wizardStepProductType.hotelsAndAccommodations'),
      text: t('wizardStepProductType.perfectForBookingStaysIncludingVariousAccommodationOptionsAndServices'),
      type: AppFocusCases.hotelType,
    },

  ], [t]);
}
