import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { ProductsCardRules } from './ProductsCard.style';
import Text from 'src/components/UI/Text/Text';
import { Icon } from '../UI/Icon/Icon';
import { Link } from 'react-router-dom';
import { Price } from '../Price/Price';
import { IProduct } from 'src/types/product';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { upsertProduct } from 'src/redux/api/products/upsertProduct';
import { resetNetworkStatus } from 'src/redux/slices/productSlice';
import { getProductDetail } from 'src/redux/api/products/getProductDetaitl';
import { ImageComponent } from '../ImageComponent/ImageComponent';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';
import { DraggableAttributes } from '@dnd-kit/core';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { CSS } from '@dnd-kit/utilities';

interface IProps {
  product: IProduct;
  isActive: boolean;
  isThird?: boolean;
  hidingFunctionality?: boolean;
  href?: string;
  onClick?: (event) => void;
  onClickHideBtn?: (event) => void;
  sortable?: {
    attributes: DraggableAttributes,
    listeners: SyntheticListenerMap,
    setNodeRef: (node: HTMLElement) => void,
    transform: any,
    transition: string,
    isSorting: boolean,
  }
}

export const ProductsCard: FC<IProps> = memo(function ProductsCard(props) {
  const {
    product,
    isActive,
    hidingFunctionality = false,
    href,
    onClick,
    isThird,
    sortable
  } = props;
  const {
    id,
    productName,
    // sold,
    imageUrls,
    category,
    subCategory,
    price: { amount, discountEnabled, oldAmount },
  } = product;

  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const { initialProductData, selections } = useAppSelector(state => state.Product);
  const [toggleEnable, setToggleEnable] = useState(false);

  const toggleEnableProductCard = useCallback(() => {
    dispatch(getProductDetail(product.id))
      .unwrap()
      .then(() => {
        setToggleEnable(true);
      })
      .catch(e => {
        console.error(e);
      });
  }, [product, dispatch]);

  useEffect(() => {
    if (!toggleEnable) return undefined;
    setToggleEnable(false);
    dispatch(
      upsertProduct({
        productData: {
          ...product,
          price: {
            ...product.price,
            amount:
              product.price.discountInPercent > 0 ? product.price.oldAmount : product.price.amount,
          },
          enabled: !product.enabled,
          imageUrls: initialProductData?.imageUrls,
          productDescription: initialProductData?.description,
          selections: selections,
          linkForOrder: String(initialProductData.deliveryAction.value),
          linkOrCode: String(initialProductData.deliveryAction.value),
        },
      }),
    ).finally(() => {
      dispatch(resetNetworkStatus('upsertNetworkStatus'));
    });
  }, [toggleEnable, initialProductData, selections, dispatch, product]);

  const { css } = useStyle(ProductsCardRules, {
    isHidden: !product.enabled,
    isActive, isThird,
    transform: CSS.Transform.toString(sortable?.transform),
    transition: sortable?.transition,
    isSorting: sortable?.isSorting
  });

  const cartContent = useMemo(() => {
    return (
      <>
        <div className={css.image}>
          <ImageComponent
            alt="product-img"
            src={imageUrls[0]}
            fallback={<Icon name="no-image" properties={{className: css.fallbackSvg}}/>}
          />
        </div>
        <div className={css.info}>
          <Text extend={css.title} mod="title" text={productName} fontSize={14} lineHeight={16}/>
          <div className={css.innerInfo}>
            <div className={css.leftInfo}>
              <div className={css.categories}>
                {category && <span>{category.title}</span>}
                {subCategory && <span className={css.separate}>·</span>}
                {subCategory && <span>{subCategory.title}</span>}
              </div>
            </div>
            <div className={css.rightInfo}>
              {discountEnabled && <Price amount={oldAmount} mathematicalSymbol={false} oldPrice/>}
              <Price amount={amount} mathematicalSymbol={false}/>
            </div>
          </div>
        </div>
      </>
    )

  }, [amount, category, css, discountEnabled, imageUrls, oldAmount, productName, subCategory])

  if (hidingFunctionality) {
    return (
      <Swiper spaceBetween={12} slidesOffsetBefore={16} slidesPerView="auto" className={css.slider}>
        <SwiperSlide className={css.slide}>
          {href ? (
            <Link className={css.card} id={`${id}`} to={href}>
              <div className={css.image}>
                <ImageComponent
                  alt="product-img"
                  src={imageUrls[0]}
                  fallback={<Icon name="no-image" properties={{ className: css.fallbackSvg }} />}
                />
              </div>
              <div className={css.info}>
                <Text
                  extend={css.title}
                  mod="title"
                  text={productName}
                  fontSize={14}
                  lineHeight={16}
                />
                <div className={css.innerInfo}>
                  <div className={css.leftInfo}>
                    <div className={css.categories}>
                      {category?.title ? (
                        <>
                          <span>{category.title}</span>
                          {subCategory && <span className={css.separate}>·</span>}
                          {subCategory && <span>{subCategory.title}</span>}
                        </>
                      ) : (
                        <Text
                          text={t('productsCard.withoutACategory')}
                          mod="text"
                          fontSize={12}
                          fontWeight={500}
                          lineHeight={16}
                          extend={css.noCategoryText}
                        />
                      )}
                    </div>
                  </div>
                  <div className={css.rightInfo}>
                    {discountEnabled && (
                      <Price amount={oldAmount} mathematicalSymbol={false} oldPrice />
                    )}
                    <Price amount={amount} mathematicalSymbol={false} />
                  </div>
                </div>
              </div>
            </Link>
          ) : (
            <div className={css.card} id={`${id}`} onClick={event => onClick && onClick(event)}>
              <div className={css.image}>
                <ImageComponent
                  alt="product-img"
                  src={imageUrls[0]}
                  fallback={<Icon name="no-image" properties={{ className: css.fallbackSvg }} />}
                />
              </div>
              <div className={css.info}>
                <Text
                  extend={css.title}
                  mod="title"
                  text={productName}
                  fontSize={14}
                  lineHeight={16}
                />
                <div className={css.innerInfo}>
                  <div className={css.leftInfo}>
                    <div className={css.categories}>
                      {category && <span>{category.title}</span>}
                      {subCategory && <span className={css.separate}>·</span>}
                      {subCategory && <span>{subCategory.title}</span>}
                    </div>
                    {/* <p className={css.statistics}>
                      Продано <span className={css.bold}>{String(sold)}</span>
                    </p> */}
                    {/* <p>
                      Модификаторов <span className={css.bold}>{modificators}</span>
                    </p> */}
                  </div>
                  <div className={css.rightInfo}>
                    {discountEnabled && (
                      <Price amount={oldAmount} mathematicalSymbol={false} oldPrice />
                    )}
                    <Price amount={amount} mathematicalSymbol={false} />
                  </div>
                </div>
              </div>
            </div>
          )}
        </SwiperSlide>
        <SwiperSlide className={css.slideBtn}>
          <button className={css.hiddenBtn} onClick={toggleEnableProductCard} data-product-id={id}>
            {!product.enabled ? <Icon name="no-view" /> : <Icon name="view" />}
          </button>
        </SwiperSlide>
      </Swiper>
    );
  }

  if (sortable) {
    return (
      <div
        className={css.cardWrapper}
        id={`${id}`}
        data-product={JSON.stringify(product)}
        onClick={event => onClick && onClick(event)}
        ref={sortable.setNodeRef}
        {...sortable.attributes}
      >
        <div className={`${css.card}`}>
          {cartContent}
          <button {...sortable.listeners} className={css.buttonDrag}>
            <Icon name="bars" properties={{className: css.iconBars}}/>
          </button>
        </div>
        {isThird && !sortable.isSorting && <div className={css.notation}>
          <Icon name={'info'}/>
          <span>{t('productsCard.bigCard')}</span>
        </div>}
      </div>
    );
  }

  return (
    <div
      className={css.cardWrapper}
      id={`${id}`}
      data-product={JSON.stringify(product)}
      onClick={event => onClick && onClick(event)}
    >
      <div className={`${css.card}`}>
        {cartContent}
      </div>
      {isThird && <div className={css.notation}>
        <Icon name={'info'}/>
        <span>{t('productsCard.bigCard')}</span>
      </div>}
    </div>
  );
});
